/* body {
    background-color: #014473;
  } */
*{
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}
p{
    margin-bottom: 0rem !important;
}
.userForm{
    height: 100vh;
    background-color: #f7f7f7;
}
.userForm .row{
        height: 100vh;
display: flex;
align-items: center;
justify-content: center;
text-align: center;
}
.userForm .form{
    padding: 30px;
background: #fff;
box-shadow: 0px 0px 3px 2px #ecedf0;
max-width: 430px;
width: 100%;

}
.userForm .form input{
    width: 100%;
    height: 44px;
    padding: 10px;
}
.userForm .logoHome img{
    max-width: 200px;
    margin: 0 auto;
    margin-bottom: 30px;
}
.submitBtn{
    background: #d84b38;
border: 0;
color: #fff;
}
.text-right{
	text-align: right;
}
.setting img{
	width: 40px;
}
.setting h4{
	font-size: 13px;
    /* margin-right: 10px; */
    font-weight: bold;
}
.logo{
	max-width: 210px;
}
.fadeOut{
     opacity:0;
     width:0;
     height:0;
     transition: width 0.2s 0.2s, height 0.2s 0.2s, opacity 0.2s;
     margin-top: 7px;
}
.fadeIn{
     opacity:1;
     width:100%;
     height:33px;
     transition: width 0.2s, height 0.2s, opacity 0.2s 0.2s;
     margin-top: 7px;
}
.userH{
    font-size: 15px;
    color: black;
}
.row.align-center{
	align-items: center;
    margin-top: 15px;
}
/* .top-row{
	margin-top: 20px;
} */
.setting_main_div{
    position: absolute;
    background: white;

}
.color-code-wrapper li button {
    width: 20px;
    height: 20px;
    margin: 5px;
    
}


.logout_div{
    text-align: right;
}
/* .dflex{
	display: flex;
	align-items: center;
} */
/* .dflex input{
    width: 100%;
    border: 0;
    font-size: 16px;
    color: #000;
} */
input:focus{
	outline: 0;
}
/* .cancle img{width: 40px;} */
.row.copy{border-bottom: 1px solid #ddd;padding: 20px 0px;}
.color-code-wrapper li{
	display: inline-block;
	margin-left: 10px;
	list-style: none;
}
.color-code-wrapper {
	padding: 10px 0px;
	border-bottom: 1px solid #ddd;
}
.color-code-wrapper span{
	height: 40px;
	width: 40px;
	display: inline-block;
	border-radius: 5px;
}
.color-code-wrapper ul{
	margin-bottom: 0;
	padding: 0;
}
.color-code-wrapper ul li:nth-child(2) span{
	border:1px solid #000;
}
.color-code-wrapper h4{
	width: 150px;
}

.pen-size h4{
	width: 150px;
}
.pen-size{
	padding: 20px 0px;
}
/* .pen-size .dflex{
	width: 100%;
}
.pen-size .dflex form{
	width: 100%;
	margin-left: 34px;
}
.pen-size .dflex form .form-group{
	display: flex;
	margin-bottom: 0;
}
.pen-size .dflex form .form-group span{
	margin-left: 30px;
} */
.pen-size{
	border-bottom: 1px solid #ddd;
}

.pad0{padding: 0;}
.instructions{
	padding: 20px 0px;
}
.instructions ul{
	padding: 0;
}
.instructions h3{
	margin-top: 30px;
	margin-bottom: 10px;
}
.instructions ul li{
	display: inline-flex;
	list-style: none;
	align-items: center;
	width: 100%;
	margin: 10px 0px;
}
.instructions a {
    background-color: #c1360b;
    padding: 5px 20px;
    color: #fff;
    margin-right: 20px;
}
.instructions ul li p{
	    margin-bottom: 0;
    color: #000;
    font-weight: 600;
}
.footer{
	border-top: 1px solid #ddd;
	padding: 20px 0px;
}
header.border-bottom{
	border-bottom: 3px solid #000 !important;
}
.logo_left{
    width: 100px;
}
.logo_right {
    float: right;
    width: 100px;
}
.top_logo{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.top_logo_left img{
    width: 180px;
}
.top_logo_right img{
    width: 100px;
}
.verify_process_section label {
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    margin-top: 10px;
}
.verify_process_section h3{
    font-size: 25px;
    font-weight: 500;
    text-align: center;
    padding: 10px 0;
}
.verify_process_section input[type=text], .verify_process_section input[type=password] {
  width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #000;
    color: #fff;
    background: #000;
    box-sizing: border-box;
    border-radius: 5px;
}
.verify_process_section button.verify_button {
    padding: 10px 30px;
    background: #000;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 5px;
}
.verify_process_section {
      display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 110px);
}
@media screen and (max-width: 767px) {
  	.verify_process_section_details .col-lg-6,
  	.verify_process_section_details .offset-lg-3{
    	padding-right: 0px !important;
    	padding-left: 0px !important
  	}
  	.verify_process_section h3{
    	font-size: 22px;
  	}
  	.verify_process_section input[type=text], .verify_process_section input[type=password]{
    	padding: 1px 15px;
  	}
}

@media only screen and (max-width: 991px){
	.row{	
		margin: 0;
	}
	.color-code-wrapper h4,
	.pen-size h4{
    width: 110px;
    font-size: 15px;
}

.color-code-wrapper span {
    height: 30px;
    width: 30px;
    display: inline-block;
    border-radius: 5px;
}
.color-code-wrapper li{
    margin-left: 1px;
    
}
.color-code-wrapper li button{
    width: 20px;
    height: 20px;
    margin: 5px;
    
}
/* .dflex{
	width: 100%;
} */
.color-code-wrapper ul
{
	margin-bottom: 0;
    padding: 0;
    width: 100%;
    text-align: right;
}
}

header.header{
    border-bottom: 1px solid #ddd;
    background-color: white;
    position: relative;
}

.pwyw-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

.pwyw-label {
    margin-left: 25px;
    font-weight: bold;
    font-size: 17px;
}

input[type=range] {
    background: rgb(205 205 205);
    border: 1px solid #9d9d9d;
}
.pwyw-container:active .pwyw-label {
    color: rgba(0, 148, 255, 1);
}

.pwyw-input {
    appearance: none;
    -webkit-appearance: none;
    -mozilla-appearance: none;
    position: relative;
    vertical-align: middle;
    width: 250px;
    border-radius: 200px;
    background: rgba(0, 148, 255, 1);
    overflow: visible;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 17px;
  width: 17px;
  border-radius: 70px;
  border-color: #ddd;
  background: #fff;
  cursor: pointer;
  box-shadow: 0 0 0pt 2pt #ddd;
}


input:focus{
  outline: 0;
}

#pwyw-input{
  height: 10px;
}

.logout_refresh_button_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.logout_refresh_button_section .refresh_buttton{
    padding:10px 20px;
    color: #fff;
    background-color: #b93e12;
    border: none;
    outline: none;
    border-radius: 5px;
}
.logout_refresh_button_section .logout_button{
    padding:10px 20px;
    color: #fff;
    background-color: #6b6b6b;
    border: none;
    outline: none;
    border-radius: 5px;
}
.user_details_section{
    margin-top: 20px;
}
.user_details_button{
        cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0px;
}
#user_deatils .license_key {
    margin-bottom: 5px !important;
}
#user_deatils .email_id {
    margin-bottom: 5px !important;
    font-size: 12px;
}
#user_deatils{
    display: none;
}
#user_deatils .email_id{

}
#user_deatils .license_key{
    
}
.footer_section_bottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.footer_p {
    font-size: 16px;
}
.footer_section_bottom p{
font-size: 16px;
}
.footer_section_bottom img{
    width: 100px;
}
.text-right{
	text-align: right;
}
.setting img{
	width: 40px;
    border: 1px solid #000;
    padding: 7px !important;
    object-fit: none;
}
.setting h4{
	font-size: 13px;
    font-weight: 700;
        color: #000;
}
.setting a:hover{
    text-decoration: none;
}
p.url_link_deatils {
    font-weight: 500;
    font-size: 18px;
}
.logo{
	max-width: 173px;
}

.top-row{
	
}
.copyBtn{
    background-color: #000;
    color: #fff;
    border: 0;
    text-transform: uppercase;
    font-size: 20px;
    max-width: 100px;
    margin-left: auto;
    padding: 5px 20px;
    border-radius: 5px;
}
.cancle{
	display: inline-block;
	width: 100%;
}
.dflex{
	display: flex;
	align-items: center;
}
.dflex input{
    width: 100%;
    border: 0;
    font-size: 24px;
    color: #000;
}
input:focus{
	outline: 0;
}
.cancle img{width: 40px;}
.row.copy{border-bottom: 1px solid #ddd;padding: 20px 0px;}
.color-code-wrapper li{
	display: inline-block;
	margin-left: 10px;
	list-style: none;
}
.color-code-wrapper {
	padding: 10px 0px;
	border-bottom: 1px solid #ddd;
}
.color-code-wrapper button{
	height: 40px;
	width: 40px;
	display: inline-block;
	border-radius: 5px;
}
.color-code-wrapper ul{
	margin-bottom: 0;
	padding: 0;
}
.color-code-wrapper ul li:nth-child(2) button{
	border:1px solid #000;
}
.color-code-wrapper h4{
	width: 150px;
}
.pen-size h4{
	width: 150px;
}
.pen-size{
	padding: 20px 0px;
}
.pen-size .dflex{
	width: 100%;
}
.pen-size .dflex form{
	width: 100%;
	margin-left: 34px;
}
.pen-size .dflex form .form-group{
	display: flex;
	margin-bottom: 0;
}
.pen-size .dflex form .form-group span{
	margin-left: 30px;
}
.pen-size{
	border-bottom: 1px solid #ddd;
}

.pad0{padding: 0;}
.instructions{
	padding: 20px 0px;
}
.instructions ul{
	padding: 0;
}
.instructions h3{
	margin-top: 30px;
	margin-bottom: 10px;
}
.instructions ul li{
	display: inline-flex;
	list-style: none;
	align-items: center;
	width: 100%;
	margin: 10px 0px;
}
.instructions a {
    background-color: #c1360b;
    padding: 5px 20px;
    color: #fff;
    margin-right: 20px;
}
.instructions ul li p{
	    margin-bottom: 0;
    color: #000;
    font-weight: 600;
}
.footer{
	border-top: 1px solid #ddd;
	padding: 20px 0px;
}
.copy-clipboard {
    position: relative;
    background-color: white;
}


@media only screen and (max-width: 767px){
    .verify_process_section {
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100vh - 80px);
    }
    .verify_process_section button.verify_button {
        padding: 1px 30px;
        background: #000;
        color: #fff;
        border: none;
        outline: none;
        border-radius: 5px;
    }
    .verify_process_section h3 {
        font-size: 25px;
        font-weight: 500;
        text-align: center;
        padding: 5px 0;
    }
    .instructions ul li{
        font-size: 12px;
    }
    .instructions a{
        padding: 5px 10px;
    }
    .copyBtn{
        background-color: #000;
        color: #fff;
        border: 0;
        text-transform: uppercase;
        font-size: 12px;
        max-width: 100px;
        margin-left: auto;
        padding: 5px 10px;
        border-radius: 5px;
    }
    .dflex input{
        font-size: 11px;
    }
    .copy-clipboard .col-sm-12, .footer .col-sm-12, .footer .col-md-8, .footer .col-md-4, .footer .col-8, .footer .col-4{
        padding-left: 0px !important;
        padding-right: 0px !important;
        
        
    }
    .color-code-wrapper button{
        width: 20px !important;
        height: 20px !important;
    }
    .color-code-wrapper h4, .pen-size h4{
        font-size: 14px !important;
    }
    .footer_p {
    font-size: 12px;
    }
    .footer_bottom p {
        font-size: 12px;
    }
    .header .col-md-6, .header .col-6{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .logout_refresh_button_section .refresh_buttton, .logout_refresh_button_section .logout_button{
        padding: 5px 10px;
    }
    p.url_link_deatils{
        font-size: 13px;
    }
    .user_details_button{
        font-size: 14px;
    }
    #user_deatils .email_id,#user_deatils .license_key {
    font-size: 14px;
}
}

@media only screen and (max-width: 991px){
	.row{	
		margin: 0;
	}
	.color-code-wrapper h4,
	.pen-size h4{
    width: 110px;
    font-size: 17px;
}
    .color-code-wrapper span {
    height: 30px;
    width: 30px;
    display: inline-block;
    border-radius: 5px;
    }
    .color-code-wrapper li{
	margin-left: 1px;
    }
    .dflex{
	width: 100%;
}
.color-code-wrapper ul
{
	margin-bottom: 0;
    padding: 0;
    width: 100%;
    text-align: right;
}
}

header.header{
    border-bottom: 2px solid #000;
    padding-top: 1px;
}

.pwyw-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

.pwyw-label {
    margin-left: 25px;
    font-weight: bold;
    font-size: 17px;
}

input[type=range] {
    background: rgb(205 205 205);
    border: 1px solid #9d9d9d;
}
.pwyw-container:active .pwyw-label {
    color: rgba(0, 148, 255, 1);
}

.pwyw-input {
    appearance: none;
    -webkit-appearance: none;
    -mozilla-appearance: none;
    position: relative;
    vertical-align: middle;
    width: 250px;
    border-radius: 200px;
    background: rgba(0, 148, 255, 1);
    overflow: visible;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 17px;
  width: 17px;
  border-radius: 70px;
  border-color: #ddd;
  background: #fff;
  cursor: pointer;
  box-shadow: 0 0 0pt 2pt #ddd;
}


input:focus{
  outline: 0;
}

#pwyw-input{
  height: 10px;
}